<template>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="#0073d1" stroke="none">
    <path d="M1641 4258 c-63 -14 -125 -79 -140 -147 -8 -37 -11 -418 -11 -1289
    l0 -1236 -148 146 c-154 154 -204 187 -275 188 -88 0 -177 -69 -203 -158 -17
    -56 -13 -95 15 -149 9 -18 174 -191 368 -386 371 -372 377 -378 464 -377 77 1
    95 16 459 384 205 206 363 373 373 394 56 117 -40 274 -177 289 -82 10 -109
    -7 -283 -180 l-163 -161 0 1254 c0 854 -4 1267 -11 1293 -16 58 -72 114 -132
    132 -57 17 -73 17 -136 3z"/>
    <path d="M3335 4252 c-61 -25 -747 -722 -765 -776 -39 -120 54 -259 184 -273
    82 -10 109 7 284 180 l162 161 0 -1254 c0 -854 4 -1267 11 -1293 33 -119 185
    -179 304 -120 43 22 64 43 89 88 l21 40 3 1264 2 1264 159 -156 c165 -162 188
    -177 271 -177 120 1 232 144 200 256 -6 22 -16 49 -23 61 -7 12 -171 181 -365
    376 -318 320 -356 356 -396 366 -60 15 -93 13 -141 -7z"/>
    </g>
    </svg>
</template>