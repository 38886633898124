<template>
    <div class="input-styling-default" :class="{'input-error': quantityInputValidator || quantityInputError}">
        <input type="text" inputmode="decimal" :placeholder="placeholder" v-model="quantityInput" @keydown="prevent" />
        <label>{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [Number, String]
        },
        placeholder: String
    },
    emits: ['update:modelValue'],
    data() {
        return {
            label: null,
            quantityInputValidator: false,
            quantityInputError: false
        }
    },
    computed: {
        quantityInput: {
            get() {
                return this.modelValue
            },
            set(value) {
                if (this.quantityInputError) this.quantityInputError = false

                value = this.parseValue(value)
                if (value === null) {
                    this.$emit('update:modelValue', null)
                    return (this.quantity = null)
                }
                value = parseFloat(value)
                this.$emit('update:modelValue', value.toString())
            }
        }
    },
    methods: {
        parseValue(value) {
            if (value === '') return null
            if (value === ',' || value === '.') return '0.'
            value = value.replace(/,/g, '.')
            return value
        },
        prevent(e) {
            const input = e.target.value
            if (e.key === 'ArrowLeft') return
            if (e.key === 'ArrowRight') return
            if ((e.key === ',' || e.key === '.') && (input.includes('.') || input.includes(','))) return e.preventDefault()
            if (!/^[0-9]$/i.test(e.key)) {
                switch (e.key) {
                case 'Home':
                case 'End':
                case 'Shift':
                case 'Control':
                case 'Escape':
                case 'Alt':
                case 'Meta':
                case 'Tab':
                case 'Backspace':
                case 'Delete':
                case 'Enter':
                case '.':
                case ',':
                case 'ArrowLeft':
                case 'ArrowRight':
                    break
                default:
                    console.log(`Prevent key: ${e.key}`)
                    return e.preventDefault()
                }
            }
        }
    }
}
</script>

<style scoped>
.input-styling-default {

}
input {
    border: none;
    outline: none;
    color: white;
    background-color: transparent;
    padding: 0;
    width: 100%;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 400;
}
input::placeholder {
    color: white;
    font-style: italic;
}
</style>
