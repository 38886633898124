<template>
  <form @submit.prevent="">
    <div class="order-side-button-row">
      <a @click="switchOrderType('buy')" :class="{ selected: orderType == 'buy' }">Buy XRP</a>
      <a @click="switchOrderType('sell')" :class="{ selected: orderType == 'sell' }">Sell XRP</a>
    </div>

    <fieldset>
      <div class="quantity-input-label-top">
        <label :style="{ color: orderType == 'buy' && limitError ? 'red' : null }">Spend</label>
        <InputQuantity v-if="orderType == 'buy'" placeholder="Fiat Amount"  v-model="fiatAmount" />
        <InputQuantity v-else placeholder="XRP Amount"  v-model="cryptoAmount" />
      </div>
      <select v-if="orderType == 'buy'" v-model="selectedFiat" style="margin-left: auto; padding-right: 20px; z-index: 10;" class="currency-label">
        <option v-for="currency in fiatOptions" :value="currency.fiat_code" :key="currency.fiat_code">{{ currency.fiat_code }}</option>
      </select>
      <div v-else style="margin-left: auto; display: flex; flex-direction: row; align-items: center;" class="currency-label">
        <div class="icon-bg-circle" style="margin-right: 5px;">
          <svg style="width: 1.2rem; margin: auto;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 424"><defs></defs><title>x</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M437,0h74L357,152.48c-55.77,55.19-146.19,55.19-202,0L.94,0H75L192,115.83a91.11,91.11,0,0,0,127.91,0Z"/><path class="cls-1" d="M74.05,424H0L155,270.58c55.77-55.19,146.19-55.19,202,0L512,424H438L320,307.23a91.11,91.11,0,0,0-127.91,0Z"/></g></g></svg>
        </div>
        <span>XRP</span>
      </div>
      <CaretDown class="select-caret" v-if="orderType == 'buy'"/>
    </fieldset>
    <span v-if="paymentMethodLimits && orderType == 'buy'" class="limits-info-text" :style="{ color: orderType == 'buy' && limitError ? 'red' : null }">Min: {{ paymentMethodLimits?.min }}, Max: {{ paymentMethodLimits?.max }} {{ paymentMethodLimits?.fiat_code }}</span>

    <div class="icon-separation-row">
      <div style="margin: 0 auto; position: relative; display: flex; flex-direction: row; align-items: center;">
        <TradeArrow style="width: 40px; height: 40px;"/>
        <LoaderSpinner v-show="loading.rates" style="width: 25px; position: absolute; left: 40px;"/>
      </div>
    </div>
    <fieldset>
      <div class="quantity-input-label-top">
        <label>Receive</label>
        <InputQuantity v-if="orderType == 'buy'" placeholder="XRP Amount"  v-model="cryptoAmount" />
        <InputQuantity v-else placeholder="Fiat Amount"  v-model="fiatAmount" />
      </div>
      <div v-if="orderType == 'buy'" style="margin-left: auto; display: flex; flex-direction: row; align-items: center;" class="currency-label">
        <div class="icon-bg-circle" style="margin-right: 5px;">
          <svg style="width: 1.2rem; margin: auto;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 424"><defs></defs><title>x</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M437,0h74L357,152.48c-55.77,55.19-146.19,55.19-202,0L.94,0H75L192,115.83a91.11,91.11,0,0,0,127.91,0Z"/><path class="cls-1" d="M74.05,424H0L155,270.58c55.77-55.19,146.19-55.19,202,0L512,424H438L320,307.23a91.11,91.11,0,0,0-127.91,0Z"/></g></g></svg>
        </div>
        <span>XRP</span>
      </div>
      <template v-else>
        <select v-model="selectedFiat" style="margin-left: auto; padding-right: 20px; z-index: 10;" class="currency-label">
          <option v-for="currency in fiatOptions" :value="currency.fiat_code" :key="currency.fiat_code">{{ currency.fiat_code }}</option>
        </select>
        <CaretDown class="select-caret"/>
      </template>
    </fieldset>

    <h2>Payment Options</h2>
    <LoaderSpinner v-if="loading.init"/>
    <h6 v-else-if="!paymentMethods || paymentMethodsAvailable.length < 1">No payment options available right now, please check again later or contact Banxa.</h6>
    <div class="payment-option-container" v-else>
      <button v-for="method in paymentMethodsAvailable" :key="method.id" @click="selectedPaymentMethodId = method.id" :class="{ selected: method.id === selectedPaymentMethodId }">
        <img :src="method.logo_url" rel="preload">
        <span>{{ method.name }}</span>
        
        <template v-for="fees of method.transaction_fees" :key="fees">
          <template v-if="fees.fiat_code === selectedFiat && fees.coin_code === 'XRP'">
            <template v-for="fee of fees.fees" :key="fee">
              <span v-if="fee.type === 'percentage'">Fee {{ formatPercentage(fee.amount) }}</span>
              <span v-else-if="fee.type === 'fixed'">Fee {{ fee.amount }} {{ fees.fiat_code }}</span>
              <span v-else>Fee {{ fee.type }} {{ fee.amount }}</span>
            </template>
          </template>
        </template>
      </button>
    </div>

    <div v-if="orderAvailable" class="order-summary">
      <h2 style="margin-top: 0">Order</h2>

      <div class="order-summary-row">
        <span>Payment amount</span>
        <span>{{ priceByMethodId?.fiat_amount }} {{ priceByMethodId?.fiat_code }}</span>
      </div>

      <div class="order-summary-row">
        <span>Amount to receive
          <br>
          <span class="rate-small">@{{ priceByMethodId?.spot_price_including_fee }} {{ priceByMethodId?.fiat_code }}</span>
        </span>
        <span>{{ priceByMethodId?.coin_amount }} {{ priceByMethodId?.coin_code }}</span>
      </div>

      <div class="order-summary-row" v-if="walletAddress">
        <span>Wallet address</span>
        <span>{{ `${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}` }}</span>
      </div>

      <div class="order-summary-row">
        <span>Gateway fees</span>
        <span>{{ priceByMethodId?.fee_amount }} {{ priceByMethodId?.fiat_code }}</span>
      </div>

      <div class="order-summary-row">
        <span>Network fees</span>
        <span>{{ priceByMethodId?.network_fee }} {{ priceByMethodId?.fiat_code }}</span>
      </div>
    </div>

    <div style="margin-top: auto; width: 100%;">
      <button type="submit" class="order-btn" @click="submitOrder()" :disabled="!orderAvailable" style="position: relative;">
        Create Order
        <LoaderSpinner v-show="loading.order" style="width: 20px; height: 20px; position: absolute; left: calc(50% + 60px);"/>   
      </button>
    </div>
  </form>
</template>

<script>
import InputQuantity from './InputQuantity.vue'
import TradeArrow from '../assets/trade-arrow.vue'
import CaretDown from '../assets/caret-down.vue'

import Big from 'big.js'
import LoaderSpinner from './LoaderSpinner.vue'

export default {
  name: 'OrderForm',
  components: { InputQuantity, TradeArrow, CaretDown, LoaderSpinner },
  props: ['walletAddress', 'fiatType'],
  data() {
    return {
      loading: {
        init: false,
        rates: false,
        order: false
      },
      fiatAmountValue: null,
      ratesTimeout: null,
      cryptoAmountValue: null,
      orderType: 'buy', // sell
      fiatValue: 'USD',
      coins: null,
      fiatOptions: null,
      paymentMethods: null,
      coinOptions: null,
      selectedPaymentMethodId: null,
      ratesData: null,
      ratesType: null,
      fetchIndex: 0,
    }
  },
  computed: {
    limitError() {
      if(isNaN(this.fiatAmount) || this.fiatAmount <= 0) return false
      if(isNaN(this.paymentMethodLimits?.min) || this.paymentMethodLimits?.min <= 0) return false
      if(isNaN(this.paymentMethodLimits?.max) || this.paymentMethodLimits?.max <= 0) return false

      if(this.Big(this.fiatAmount).lt(this.paymentMethodLimits?.min)) return true
      if(this.Big(this.fiatAmount).gt(this.paymentMethodLimits?.max)) return true

      return false
    },
    orderAvailable() {
      if(this.selectedPaymentMethodId && this.ratesData?.spot_price && !this.limitError && !this.loading.rates) return true
      else return false
    },
    selectedFiat: {
      get() {
        return this.fiatValue
      },
      set(value) {
        this.fiatValue = value
        this.cryptoAmountValue = null
        this.selectedPaymentMethodId = null
        this.setFiatAmount(this.fiatAmount)
      }
    },
    fiatAmount: {
      get() {
        if(this.ratesType === 'coin') return this.priceByMethodId?.fiat_amount || this.fiatAmountValue
        return this.fiatAmountValue
      },
      set(value) {
        this.fiatAmountValue = value
        this.setFiatAmount(value)
      }
    },
    cryptoAmount: {
      get() {
        if(this.ratesType === 'fiat') return this.priceByMethodId?.coin_amount || this.cryptoAmountValue
        return this.cryptoAmountValue
      },
      set(value) {
        this.cryptoAmountValue = value
        this.setCryptoAmount(value)
      }
    },
    priceByMethodId() {
      try{
        for(const rate of this.ratesData?.prices) {
          if(rate.payment_method_id === this.selectedPaymentMethodId) return rate
        }
      } catch(e) {
        console.log(e)
      }
      return null
    },
    paymentMethodLimits() {
      if(!this.selectedPaymentMethodId) return null
      for(let method of this.paymentMethodsAvailable) {
        if(this.selectedPaymentMethodId == method.id) {
          for(let limits of method.transaction_limits) {
            if(limits.fiat_code === this.selectedFiat) return limits
          }
        }
      }
      return null
    },
    paymentMethodsAvailable() {
      if(!this.paymentMethods) return []
      if(this.paymentMethods.length < 1) return []

      return this.paymentMethods.filter(method => {
        let test = this.methodAvailable(method)
        if(!this.selectedPaymentMethodId && test) this.selectedPaymentMethodId = method.id
        return test
      })
    }
  },
  async created() {
    await this.init()
    this.selectedFiat = this.fiatType || 'USD'
  },
  methods: {
    async switchOrderType(type) {
      if(type === 'sell') return alert('Off-ramping or Selling XRP is not avilable yet with Banxa')

      this.ratesData = null
      this.selectedPaymentMethodId = null
      this.orderType = type
      await this.init()
    },
    Big(value) {
      try{
        return new Big(value)
      } catch(e) {
        console.log(e)
      }
    },
    async init() {
      this.loading.init = true
      /* eslint-disable */
      // return
      try {
        const res = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/init?type=${this.orderType}`)
        if(res.status < 200 || res.status > 299) throw res.status

        const data = await res.json()
        this.fiatOptions = data.fiats
        this.paymentMethods = data.methods
        this.coinOptions = data.coins

        this.loading.init = false
        return data
      } catch(e) {
        console.log(e)
        alert('Error connecting to Banxa')
      }
      this.loading.init = false
    },
    async fetchRates(url, fiat) {
      this.loading.rates = true
      this.fetchIndex++
      let fetchI = this.fetchIndex
      try {
        const res = await fetch(url)
        const data = await res.json()
        
        if(fetchI < this.fetchIndex) throw 'Index error'
        this.ratesData = data
        
        console.log(data)
        if(fiat) {
          this.ratesType = 'fiat'
          // this.cryptoAmountValue = this.Big(this.fiatAmount).div(data.spot_price).round(6).toString()
        } else {
          this.ratesType = 'coin'
          // this.fiatAmountValue = this.Big(this.cryptoAmount).times(data.spot_price).toString()
        }
      } catch(e) {
        // eslint-disable-next-line
        if(e === 'Index error') {}
        else {
          console.error('Error fetching rates:', e)        
          alert('Error fetching rates')
        }
      }
      this.loading.rates = false
    },
    setFiatAmount(value) {
      if(this.ratesTimeout !== null) {
        clearTimeout(this.ratesTimeout)
        this.ratesTimeout = null
      }

      if(isNaN(value) || value <= 0) {
        this.cryptoAmountValue = null
        this.ratesData = null
        return
      }

      const url = new URL(process.env.VUE_APP_API_ENDPOINT + '/quote')
      const params = {
        source: this.orderType === 'buy' ? this.selectedFiat : 'XRP',
        target: this.orderType === 'buy' ? 'XRP' : this.selectedFiat,
      }

      if(this.orderType === 'buy') params['source_amount'] = value

      url.search = new URLSearchParams(params).toString()
      this.ratesTimeout = setTimeout(this.fetchRates.bind(null, url, 'fiat'), 500)
    },
    setCryptoAmount(value) {
      if(this.ratesTimeout !== null) {
        clearTimeout(this.ratesTimeout)
        this.ratesTimeout = null
      }
      
      if(isNaN(value) || value <= 0) {
        this.fiatAmountValue = null
        this.ratesData = null
        return
      }

      const url = new URL(process.env.VUE_APP_API_ENDPOINT + '/quote')
      const params = {
        source: this.orderType === 'buy' ? this.selectedFiat : 'XRP',
        target: this.orderType === 'buy' ? 'XRP' : this.selectedFiat,
      }

      if(this.orderType === 'buy') params['target_amount'] = value

      url.search = new URLSearchParams(params).toString()
      this.ratesTimeout = setTimeout(this.fetchRates.bind(null, url), 500)
    },
    methodAvailable(method) {
      return method?.supported_fiat?.includes(this.selectedFiat) && method?.status === 'ACTIVE'
    },
    formatPercentage(fee) {
      return this.Big(fee).times(100).toString() + '%'
    },
    async submitOrder() {
      if(this.loading.order) return

      this.loading.order = true
      const returnUrl = process.env.VUE_APP_XAPP_DEEPLINK
      const account = this.walletAddress

      try {
        let params = {}

        if(this.orderType === 'buy') {
          params = {
            account_reference: account,
            payment_method_id: this.selectedPaymentMethodId,
            source: this.selectedFiat,
            source_amount: this.orderType === 'buy' ? this.fiatAmount.toString() : this.cryptoAmount.toString(),
            target: this.orderType === 'buy' ? 'XRP' : this.selectedFiat,
            wallet_address: account,
            // wallet_address_tag: '123456',
            // blockchain: 'ripple',
            return_url_on_success: returnUrl,
            // iframe_domain: 'https://xumm.app',
            // refund_address: address
          }
        } else if(this.orderType === 'sell') {
          params = {
            account_reference: account,
            source: 'XRP',
            target: this.selectedFiat,
            refund_address: account,
            return_url_on_success: returnUrl,
          }
        }

        console.log(params)

        const res = await fetch(process.env.VUE_APP_API_ENDPOINT + '/orders', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        })
        const data = await res.json()
        console.log(data)
        console.log(data.data.order.checkout_url)
        await window.xapp.openBrowser({ url: data.data.order.checkout_url })
      } catch(e) {
        console.log('Error submitting order', e)
        alert('Error submitting order')
      }
      this.loading.order = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form {
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  flex: 1 1 0;
  margin: 0 auto;
}
fieldset {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  width: -webkit-fill-available;
  padding: 15px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.payment-option-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.payment-option-container button {
  width: 150px;
  max-width: 170px;
  margin: 5px auto;
  opacity: 0.6;
}
.payment-option-container > button > img {
  background-color: white;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 5px;
}
.payment-option-container > button > span:not(:first-child) {
  font-size: 0.9rem;
}
h2 {
  color: #0073d1;
}
h3 {
  margin: 40px 0 0;
}
h4 {
  color: #0073d1;
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.icon-separation-row {
  display: flex;
  flex-direction: row;
  max-height: 40px;
  margin: 10px 0;
  align-items: center;
}
.order-side-button-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
}
.order-side-button-row a {
  color: grey;
  font-weight: bold;
  font-size: 0.8rem;
  width: inherit;
  background-color: rgb(58, 67, 85);
  padding: 12px 0;
  cursor: pointer;
}
.order-side-button-row a.selected {
  background-color: rgb(21, 204, 182);
  color: black;
}
.order-side-button-row a:first-child {
  border-radius: 10px 0 0 10px;
}
.order-side-button-row a:last-child {
  border-radius: 0 10px 10px 0;
}
.quantity-input-label-top {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.quantity-input-label-top label {
  font-size: 0.7rem;
  font-weight: bold;
  margin-right: auto;
  color: grey;
}
.quantity-input-label-top input {
  border: none;
  background-color: none;
}
select {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
}
.currency-label {
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
}
button {
  border: 1px solid transparent;
  color: white;
  outline: none;
  background-color: transparent;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
button:hover {
  cursor: pointer;
  /* border: 1px solid #0073d1; */
  border: 1px solid transparent;
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
  background-color: #6a6a6a52;
  color: grey;
}
button img {
  object-fit: contain;
  max-height: 30px;
  height: 30px;
  max-width: 100px;
  width: 100px;
  border: 1px solid grey;
  margin-bottom: 2px;
}
button span {
  font-size: 1.05rem;
  font-weight: 500;
}
button.selected {
  opacity: 1 !important;
}
button.selected > img {
  border: 3px solid #0073d1;
  margin-top: -2px;
  margin-bottom: 0;
}
.order-btn {
  /* background-color: #0073d1; */
  background-color: rgb(21, 204, 182);
  padding: 15px 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.1rem;
}
.order-summary {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 20px 10px;
  width: -webkit-fill-available;
}
span.rate-small {
  font-size: 0.8rem;
  font-style: italic;
}
.order-summary-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff4a;
  padding: 12px 0;
}
.order-summary-row > span:first-child {
  color: white;
  font-weight: 300;
}
.order-summary > span:last-child {
  color: white;
  font-weight: 500;
}
.icon-bg-circle {
  border-radius: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}
.select-caret {
  width: 15px;
  height: 15px;
  fill: white;
  position: relative;
  left: -15px;
  margin-right: -15px;
}
.limits-info-text {
  font-style: italic;
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin-top: 2px;
  margin-bottom: calc(-0.9rem - 2px);
  margin-right: auto;
  margin-left: 10px;
}
</style>
